import React from 'react';
import { Box, Typography } from '@mui/material';
import { HotelBackgroundPalms } from './helpers/images';

export default function ReviewIntroBox() {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '35vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${HotelBackgroundPalms})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          filter: 'blur(2px)',
          zIndex: 0,
        }}
      />
      <Typography
        sx={{ color: 'white', fontSize: '4rem', margin: 'auto', position: 'relative', textAlign:"center" }}
      >
        Hotel Reviews
      </Typography>
    </Box>
  );
}
