import React from 'react';
import ContactUsIntroBox from '../ContactUsIntroBox';
import { Box, useTheme } from '@mui/material';
import ContactUsForm from '../ContactUsForm';
import ContactInformation from './ContactUsInformation';

const ContactUsPage: React.FC = () => {
  const theme = useTheme();

  return (
    <Box>
      <ContactUsIntroBox />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column", // Change flex direction to column
          alignItems: "center", // Center items horizontally
          width: '100%', // Change width to 100%
          margin: "auto"
        }}
      >
        <Box
          sx={{
            width: '90%', // Change width to 90% for larger screens
            [theme.breakpoints.down(500)]: {
              width: "100%", // Change width to 100% for smaller screens
            },
          }}
        >
          <ContactInformation />
        </Box>
        <Box
          sx={{
            width: "90%", // Change width to 90% for larger screens
            [theme.breakpoints.down(500)]: {
              width: "100%", // Change width to 100% for smaller screens
            },
          }}
        >
          <ContactUsForm />
        </Box>
      </Box>
    </Box>
  );
};

export default ContactUsPage;
