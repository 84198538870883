import {
  Box,
  Button,
  Typography,
  darken,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { HotelInfo, HotelReview } from "./helpers/interfaces";
import CircleProgressBar from "./shared/CircularProgressWithLabel";

interface SingleReviewInterface {
  hotel: HotelInfo;
  review: HotelReview;
}
export default function SingleReview(props: SingleReviewInterface) {
  const matchScreen_500 = useMediaQuery("(max-width:500px)");
  const theme = useTheme();

  const handleBoxClick = () => {
    window.open(props.hotel.url, "_blank");
  };

  return (
    <Box
      className="singleReviewContainer"
      sx={{
        display: "flex",
        flexDirection: "row",
        border: "1px solid black",
        borderRadius: "12px",
        padding: 2,
        backgroundColor: darken("rgb(255,255,255)", 0.05),
        color: "white",
        transition: "all .17s ease-in-out",
        cursor: "pointer",
        flexWrap: "wrap",
        overflow: "hidden",
        ":hover": {
          transform: "scale(1.02)",
        },
      }}
    >
      <Box
        // className="reviewImageBox"
        sx={{
          display: "flex",
          width: matchScreen_500 ? "100%" : "max-content",
          alignItems: "center",
          m: "auto",
          justifyContent: "center",
        }}
      >
        <img
          className="hotelLogo"
          style={{
            objectFit: "cover",
            marginTop: "auto",
            marginBottom: "auto",
          }}
          src={props.hotel.hotel_logo}
          alt={props.hotel.hotel_name}
          loading="lazy"
        />
      </Box>
      <Box
        // className="reviewTextBox"
        sx={{
          flex: 1,
          display: "flex",
          my: "auto",
          padding: 2,
          height: "190px",
          overflow: "hidden",
        }}
      >
        <Typography
          // className="reviewText"
          component={"span"}
          sx={{
            px: matchScreen_500 ? 0 : 2,
            textAlign: "justify",
            color: "black",
            display: "-webkit-box",
            WebkitLineClamp: 8,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {props.review.text}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: matchScreen_500 ? "1rem" : 0,
          mx: matchScreen_500 ? "1rem" : "2rem",
          display: "flex",
          flexDirection: "column",
          width: matchScreen_500 ? "100%" : "max-content",
          justifyContent: matchScreen_500 ? "space-between" : "space-around",
          alignItems: "center",
          [theme.breakpoints.down(500)]: {
            flexDirection: "row",
            width: "100%",
          },
        }}
      >
        {/* <Box
          // className="reviewProgressBox"
          sx={
            {
              // justifyContent: "center",
              // margin: "auto",
              // alignItems: "center",
            }
          }
        > */}
        <CircleProgressBar
          key={props.review.id}
          value={props.review.total_review_score}
          max={10}
        />
        {/* </Box> */}

        {/* <Box
          // className="reviewButtonBox"
          sx={{}}
        > */}
        <Button
          variant="contained"
          color="secondary"
          sx={{
            heigth: matchScreen_500 ? "30px" : "50px",
            width: matchScreen_500 ? "9rem" : "13rem",
            fontSize: matchScreen_500 ? "0.7rem" : "0.9rem",
            backgroundColor: "black",
          }}
          onClick={handleBoxClick}
        >
          Visit website
        </Button>
        {/* </Box> */}
      </Box>
    </Box>
  );
}
