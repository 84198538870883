import { Box } from '@mui/material';
import './App.css';

import RoutesComponent from './Components/Routes/routes';
function App() {
  return (
    <Box sx={{ flex: 1, backgroundColor: 'black', width: '100%', height: "100%" }}>
      <RoutesComponent />
    </Box>
  );
}

export default App;
