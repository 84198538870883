import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { PurpleBackground } from '../helpers/images';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

const ContactInformation: React.FC = () => {
  return (
    <Box
    sx={{
      position: 'relative',
      height:"22rem",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      padding: 5,
      borderRadius: 4,
      flexDirection: 'column',
      textAlign: 'center',
      maxWidth: 680,
      margin: '0 auto',
      marginTop:"2rem",
      marginBottom:"2rem",
      marginLeft:"auto"
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${PurpleBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        filter: 'blur(1px)',
        zIndex: 0,
        padding:2
      }}
    />
    <Box sx={{position:"relative", display:"flex", flexDirection:"column", height:"100%"}}>
        <Box sx={{marginBottom:"auto"}}>
        <Typography variant="h4" gutterBottom sx={{color:"white"}}>
        Contact Information
      </Typography>
      <Typography sx={{color:"white"}}>Fill up the form and our team will contact you within 24 hours</Typography>
        </Box>

      <Box sx={{ display: 'flex', flexDirection:"column" , margin:"auto"}}>
        <Box sx={{display:"flex", flexDirection:"row", columnGap:5, marginTop:"1rem"}}>
        <LocalPhoneIcon fontSize='large' color="secondary"/>
        <Typography sx={{fontSize:"1.4rem", color:"white"}} >+39-111-111-11-11</Typography>

        </Box>
        <Box sx={{display:"flex", flexDirection:"row", columnGap:5, marginTop:"1rem"}}>
        <EmailIcon fontSize='large' color="secondary"/>
        <Typography sx={{fontSize:"1.4rem", color:"white"}} >info@hotelReviews.com</Typography>

        </Box>
        <Box sx={{display:"flex", flexDirection:"row", columnGap:5, marginTop:"1rem"}}>
        <LocationOnIcon fontSize='large' color="secondary"/>
        <Typography sx={{fontSize:"1.4rem", color:"white"}} >123 Main Street, City, Country</Typography>

        </Box>
      </Box>

      <Box sx={{display:'flex', flexDirection:"row", margin:"auto", marginTop:"auto", marginBottom:"0.2rem"}}>
      <IconButton href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" sx={{ color: "#fff" }}>
                <FacebookIcon />
              </IconButton>
              <IconButton href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" sx={{ color: "#fff" }}>
                <TwitterIcon />
              </IconButton>
              <IconButton href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" sx={{ color: "#fff" }}>
                <InstagramIcon />
              </IconButton>
      </Box>
    </Box>
    
  </Box>
    
  );
};

export default ContactInformation;
