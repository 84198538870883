import React from "react";
import { Box, Typography, Link } from "@mui/material";


const Footer: React.FC = () => {
  return (
    <footer style={footerStyles}>
      <Box sx={{ maxWidth: "1200px", margin: "0 auto", padding: "20px" }}>
        {/* <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box>
            <Typography variant="h6" sx={{ color: "#fff", marginBottom: "10px" }}>Contact Us</Typography>
            <Typography sx={{ color: "#fff", marginBottom: "5px" }}>
              Phone: +1 123-456-7890
            </Typography>
            <Typography sx={{ color: "#fff", marginBottom: "5px" }}>
              Email: info@example.com
            </Typography>
            <Typography sx={{ color: "#fff" }}>
              Address: 123 Main Street, City, Country
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" sx={{ color: "#fff", marginBottom: "10px" }}>Connect with Us</Typography>
            <Box sx={{ display: "flex" }}>
              <IconButton href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" sx={{ color: "#fff" }}>
                <FacebookIcon />
              </IconButton>
              <IconButton href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" sx={{ color: "#fff" }}>
                <TwitterIcon />
              </IconButton>
              <IconButton href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" sx={{ color: "#fff" }}>
                <InstagramIcon />
              </IconButton>
              <IconButton href="mailto:info@example.com" sx={{ color: "#fff" }}>
                <EmailIcon />
              </IconButton>
            </Box>
          </Box>
        </Box> */}
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderTop: "1px solid #ccc", paddingTop: "10px" }}>
          <Typography variant="body2" color="textSecondary" sx={{ color: "#fff" }}>
            &copy; {new Date().getFullYear()} Besthotels. All rights reserved.
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ color: "#fff" }}>
            <Link href="/privacy-policy" sx={{ color: "#fff", marginRight: "10px" }}>Privacy Policy</Link> | <Link href="/terms-and-conditions" sx={{ color: "#fff" }}>Terms and Conditions</Link>
          </Typography>
        </Box>
        <Box sx={{ marginTop: "20px" }}>
          <Typography variant="body2" color="textSecondary" sx={{ color: "#fff" }}>
            Discover the best hotels in Europe based on guest reviews. Indulge in luxurious accommodations, impeccable service, and unforgettable experiences. Whether you're planning a romantic getaway, family vacation, or business trip, these handpicked hotels promise to exceed your expectations. Book your next European adventure with confidence and immerse yourself in the finest hospitality Europe has to offer.
          </Typography>
        </Box>
      </Box>
    </footer>
  );
};

const footerStyles: React.CSSProperties = {
  backgroundColor: "#333",
  padding: "20px",
  color: "#fff",
};

export default Footer;
