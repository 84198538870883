// workerService.js
//fetch Hostel data 

export async function fetchWorkerData() {
  try {
    const response = await fetch('https://api.elchin-ferhadov.workers.dev/');
    const data = await response.json();
    console.log("TEST")
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
}
