import { useEffect, useState } from 'react';
import { Box, Rating, Typography, darken } from "@mui/material";
import { HotelInfo } from "../helpers/interfaces";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import { fetchWorkerData } from '../../Service/fetchListService';

interface SingleHotelInterface {
  hotel: HotelInfo;
}

export default function SingleHotel(props: SingleHotelInterface) {
  const [hotelData, setHotelData] = useState<HotelInfo[]>([]);

  useEffect(() => {
    fetchWorkerData()
      .then(data => {
        if (data) {
          setHotelData(data);
          console.log(hotelData)
          console.log(data)


        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleBoxClick = () => {
    window.open(props.hotel.url, "_blank");
  };

  return (
    <Box
      className="singleHotelContainer"
      onClick={handleBoxClick}
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid black",
        borderRadius: "8px",
        backgroundColor: darken("rgb(105,105,105)", 0.7),
        color: "white",
        cursor: "pointer",
        overflow: "hidden",
        height: "max-content",
        "& .hotelImage": {
          transformOrigin: "center",
          transform: "scale(1)",
          transition: "transform 0.4s ease-in-out",
        },
        ":hover": {
          ".hotelImage": {
            transform: "scale(1.15)",
          },
          ".card-details": {
            color: "#AEEAF7",
          },
          svg: {
            opacity: 1,
            transform: "translateX(0)",
          },
        },
      }}
    >
      {props.hotel.img_source && (
        <Box
          sx={{
            display: "flex",
            height: "25vh",
            overflow: "hidden",
          }}
        >
          <img
            className="hotelImage"
            style={{
              width: "100%",
              objectFit: "cover",
            }}
            src={props.hotel.img_source}
            alt={props.hotel.hotel_name}
            loading="lazy"
          />
        </Box>
      )}
      <Rating
        name="read-only"
        value={props.hotel.stars}
        readOnly
        sx={{ mx: 2, mt: 2 }}
      />
      <Box
        sx={{
          mx: 2,
          mb: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            className="card-details"
            component={"span"}
            sx={{ textAlign: "left", fontSize: "1.8rem" }}
          >
            {props.hotel.hotel_name}
          </Typography>
          <EastOutlinedIcon
            sx={{
              color: "#AEEAF7",
              ml: "1em",
              transform: "translateX(-1.5em)",
              opacity: 0,
              transition: "all 0.4s",
            }}
          />
        </Box>
        <Typography
          className="card-details"
          component={"span"}
          sx={{
            marginLeft: "auto",
            marginRight: "5px",
            maxWidth: "50%",
            textAlign: "right",
          }}
        >{`${props.hotel.hotel_country}, ${props.hotel.hotel_city}, ${props.hotel.hotel_adress}`}</Typography>
      </Box>
    </Box>
  );
}
