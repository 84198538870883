import { useEffect, useState } from 'react';
import { Box, Typography } from "@mui/material";
import SingleHotel from "../Hotels/SingleHotel";
import IntroBox from "../IntroBox";
import { fetchWorkerData } from "../../Service/fetchListService"; // Import the function to fetch data from the API
import { HotelInfo } from "../helpers/interfaces"; // Import the HotelInfo interface

export default function MainPage() {
  const [hotelInfoList, setHotelInfoList] = useState<HotelInfo[]>([]); // Specify the type of hotelInfoList

  useEffect(() => {
    fetchWorkerData()
      .then(data => {
        if (data) {
          setHotelInfoList(data);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <Box sx={{ flex: 1 }}>
      <IntroBox />
      <Typography
        fontSize={"2.2rem"}
        sx={{ color: "white", textAlign: "center", marginTop: "0.5rem" }}
      >
        {" "}
        Hotels{" "}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          rowGap: 3,
          columnGap: 4,
          width: "95%",
          margin: "auto",
          marginTop: "0.5rem",
          padding: 2,
          marginLeft: "auto",
          marginRight: "auto",
          justifyContent: "center",
        }}
      >
        {hotelInfoList.map((hotel) => {
          return <SingleHotel key={hotel.id} hotel={hotel} />;
        })}
      </Box>
    </Box>
  );
}
