import React from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  styled,
  useTheme,
} from '@mui/material';


const FormContainer = styled(Container)(({ theme }) => ({
  backgroundColor: '#f3f3f3',
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  heigth:"25rem",
  maxWidth: 580,
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  textAlign: 'center',
}));

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent:"center",
  flexWrap:"wrap",
  width: '100%',
  columnGap:5,
  rowGap:2
});

const Input = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  fontSize:"1.9rem"
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '50%',
  alignSelf: 'center',
}));

const ContactUsForm: React.FC = () => {
    const theme=useTheme()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  return (
    <FormContainer sx={{marginTop:"2rem", marginBottom:"2rem", marginRight:"2rem"}}>
      <Title variant="h5">
        Get in touch
      </Title>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          label="First name"
          variant='standard'
          required
          sx={{width:"43%",
          [theme.breakpoints.down(500)]: {
        
            width: "86%",
          },
        }}
        />
        <Input
          type="text"
          label="Last name"
          variant='standard'
          required
          sx={{width:"43%",
          [theme.breakpoints.down(500)]: {
        
            width: "86%",
          },
        }}
        />
        <Input
          type="email"
          label="Email"
          variant="standard"
          required
          sx={{width:"43%",
          [theme.breakpoints.down(500)]: {
        
            width: "86%",
          },
        }}
        />
        <Input
          type="phone"
          label="Phone number"
          variant="standard"
          required
          sx={{width:"43%",
          [theme.breakpoints.down(500)]: {
        
            width: "86%",
          },
        }}
        />
        <Input
          multiline
          rows={2}
          label="Message"
          variant="standard"
          required
          sx={{width:"86%"}}
        />
        <SubmitButton
          type="submit"
          variant="contained"
          color="success"
          sx={{backgroundColor:"black"}}
        >
          Send Message
        </SubmitButton>
      </Form>
    </FormContainer>
  );
};

export default ContactUsForm;
