import { useEffect, useState } from 'react';
import { Box } from "@mui/material";
import SingleReview from "../SingleReview";
import ReviewIntroBox from "../ReviewIntroBox";
import { fetchWorkerData } from "../../Service/fetchListService"; // Import the function to fetch data from the API
import { HotelReview, HotelInfo } from "../helpers/interfaces"; // Import the interfaces for hotel review and hotel info

export default function ReviewsPage() {
    const [hotelReviews, setHotelReviews] = useState<HotelReview[]>([]);
    const [hotelInfoList, setHotelInfoList] = useState<HotelInfo[]>([]);

    useEffect(() => {
        // Fetch hotel reviews
        fetchWorkerData()
            .then(data => {
                if (data) {
                    setHotelReviews(data.hotelReviews);
                    setHotelInfoList(data.hotelInfoList);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    // Initialize hotelReviews with an empty array to prevent "Cannot read properties of undefined" error
    const reviewsToDisplay = hotelReviews || [];

    return (
        <Box>
            <ReviewIntroBox />
            {/* <Typography fontSize={'2.2rem'} sx={{color:'white', textAlign:'center', marginTop:'0.5rem'}} > Reviews </Typography> */}
            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: 'center', rowGap: 1, marginTop: "1rem", marginBottom: "1rem" }}>
                {reviewsToDisplay.length > 0 && reviewsToDisplay // Check if reviewsToDisplay is populated
                    .sort((a, b) => b.total_review_score - a.total_review_score)
                    .map((review) => {
                        const HotelInfoBasedOnReview = hotelInfoList.find((hot) => hot.id === review.hotel_id);
                        // Conditional rendering to handle the case when HotelInfoBasedOnReview is undefined
                        return HotelInfoBasedOnReview ? (
                            <SingleReview hotel={HotelInfoBasedOnReview} review={review} key={review.id} />
                        ) : null;
                    })}
            </Box>
        </Box>
    );
}
