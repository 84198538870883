import { Box, Typography, useTheme } from "@mui/material";
import "../App.css";

export default function IntroBox() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: "auto",
        backgroundColor: "#ffffff", // White background
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px", // Added padding for spacing
      }}
    >
      <Box
        sx={{
          width: "80%", // Adjust as needed
          maxWidth: "800px", // Maximum width
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 3,
        }}
      >
        <Typography
          className="introMainText"
          sx={{
            textAlign: "center",
            color: "black", // Text color
            fontFamily: "Canela",
            fontSize: "1.5rem",
            width: "calc(100% - 20px)", // Width equal to container width with 10px margin on both sides
            margin: "0 10px", // 10px margin on both sides
            [theme.breakpoints.down("sm")]: {
              fontSize: "1.1rem",
            },
            [theme.breakpoints.down("xs")]: {
              fontSize: "1rem",
            },
          }}
        >
          Welcome to the website!
        </Typography>
        <Typography
          className="introMainText"
          sx={{
            textAlign: "center",
            color: "black", // Text color
            fontFamily: "Canela",
            fontSize: "2.5rem",
            width: "calc(100% - 20px)", // Width equal to container width with 10px margin on both sides
            margin: "0 10px", // 10px margin on both sides
            [theme.breakpoints.down("sm")]: {
              fontSize: "1.2rem",
            },
            [theme.breakpoints.down("xs")]: {
              fontSize: "1rem",
            },
          }}
        >
          The Best Hotels in Europe in 2023, according to guest reviews
        </Typography>
        <Typography
          className="introSubText"
          sx={{
            color: "black", // Text color
            fontStyle: "italic",
            fontFamily: "FarnhamDisplay",
            textAlign: "center",
            marginTop: "2rem",
            fontSize: "1.2rem",
            [theme.breakpoints.down("sm")]: {
              fontSize: "1rem",
            },
            [theme.breakpoints.down("xs")]: {
              fontSize: "0.9rem",
            },
          }}
        >
          Nothing surpasses the satisfaction of checking into a great hotel—and these are the best in Europe
        </Typography>
        <Typography
          sx={{
            marginTop: "auto",
            fontSize: "1em",
            [theme.breakpoints.down("sm")]: {
              fontSize: "0.9rem",
            },
            [theme.breakpoints.down("xs")]: {
              fontSize: "0.8rem",
            },
          }}
        >
          {new Date().toDateString()}
        </Typography>
      </Box>
    </Box>
  );
}
