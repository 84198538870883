import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MainPage from '../Pages/MainPage';
import AboutPage from '../Pages/AboutPage';
import Navbar from '../Navigation/Navbar';
import ReviewsPage from '../Pages/ReviewsPage';
import Footer from '../Footer';
import ContactUsPage from '../Pages/ContactUsPage';

const RoutesComponent = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/reviews" element={<ReviewsPage />} />
        <Route path="/contactUs" element={<ContactUsPage />} />
        <Route path="*" element={<Navigate to="/" />} /> 
      </Routes>
      <Footer/>
    </Router>
  );
};

export default RoutesComponent;
