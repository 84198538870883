import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { darken } from '@mui/material';

export default function Navbar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ color: 'white', backgroundColor: darken('rgb(105,105,105)', 0.7) }}>
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            {/* <MenuIcon /> */}
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link style={{ textDecoration: 'none', color: 'white', marginRight: '1rem' }} to="/">Best Hotels</Link>

          </Typography>
          <Link to="/" style={{ textDecoration: 'none', color: 'white', marginRight: '1rem' }}>
            Home
          </Link>
          <Link to="/reviews" style={{ textDecoration: 'none', color: 'white', marginRight: '1rem' }}>
            Reviews
          </Link>
          <Link to="/contactUs" style={{ textDecoration: 'none', color: 'white', marginRight: '1rem' }}>
            Contact us
          </Link>
          <Link to="/about" style={{ textDecoration: 'none', color: 'white', marginRight: '1rem' }}>
            About
          </Link>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
