import React from "react";
import {
  CircularProgress,
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";

interface CircleProgressBarProps {
  value: number;
  max: number;
  text_color?: string;
  bar_color?: string;
}

const CircleProgressBar: React.FC<CircleProgressBarProps> = ({
  value,
  max,
  text_color,
  bar_color,
}) => {
  const progress = (value / max) * 100;
  const matchScreen_500 = useMediaQuery("(max-width:500px)");

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={progress}
        size={matchScreen_500 ? "3rem" : "5rem"}
        color="success"
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          sx={{
            color: text_color ? text_color : "black",
            fontSize: matchScreen_500 ? "1.1rem" : "1.4rem",
          }}
        >{`${value}`}</Typography>
      </Box>
    </Box>
  );
};

export default CircleProgressBar;
