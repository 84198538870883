import React from "react";
import { Box, Typography } from "@mui/material";
import AboutIntroBox from "../AboutIntroBox";

export default function AboutPage() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <AboutIntroBox />
      <Typography
        className="aboutText"
        variant="body1"
        sx={{
          color: "white",
          textAlign: "justify",
          marginTop: "2rem",
          maxWidth: "1000px",
          padding: "0 1rem",
          lineHeight: "1.5",
          marginBottom:"2rem"
        }}
      >
        Welcome to our Hotels webpage! We understand that choosing the perfect
        hotel for your stay is crucial, and we're here to assist you in making
        the best decision. At our hotel selection platform, we pride ourselves
        on curating a diverse range of accommodations to cater to every
        traveler's needs and preferences. With our extensive knowledge of the
        hospitality industry, we have handpicked a selection of exceptional
        hotels that offer unparalleled comfort, outstanding amenities, and
        exceptional service. Whether you're seeking a luxurious getaway, a
        budget-friendly option, or a family-friendly resort, we have the ideal
        choice for you. Our team has meticulously researched and evaluated each
        hotel to ensure they meet our high standards of quality. We consider
        factors such as location, customer reviews, facilities, and value for
        money. By providing comprehensive information and honest reviews, we aim
        to empower you with the necessary details to make an informed decision.
        No matter where your adventures take you, our user-friendly interface
        allows you to effortlessly browse through a wide array of hotels,
        compare prices, and explore detailed descriptions and images. We believe
        in making your hotel selection process as smooth as possible, so you can
        focus on enjoying your trip to the fullest. At our Hotels webpage, your
        satisfaction is our top priority. We are committed to helping you find
        the perfect accommodation that suits your specific requirements and
        guarantees a memorable and enjoyable stay. Trust us to be your reliable
        source for exceptional hotels and embark on an unforgettable journey
        with confidence. Choose us to find the best hotel that meets your needs
        and embark on an unforgettable journey with confidence.
      </Typography>
    </Box>
  );
}
